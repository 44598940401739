import React from "react";
import { Player } from "@lottiefiles/react-lottie-player";
import { PageSection } from "../components/PageSection";
import { Paragraph } from "../components/Typography/Paragraph";
import { SubHeading } from "../components/Typography/SubHeading";
import { gridSquares } from "../styles/grid";
import { Link } from "gatsby";
import { Head } from "../components/Head";
import LottieImage404 from "../../static/images/404_animation/404_Lighthouse_FA.json";
import styled from "styled-components";

const MyParagraph = styled(Paragraph)`
    text-align: center;
    padding-top: ${gridSquares(2)};
    padding-bottom: ${gridSquares(1)};
`;

const MyPlayer = styled(Player)`
    padding-bottom: ${gridSquares(3)};
    padding-left: ${gridSquares(2)};
    padding-right: ${gridSquares(2)};
`;

const PageNotFound = () => (
    <>
        <Head title="404" />
        <PageSection>
            <MyPlayer autoplay loop src={LottieImage404}></MyPlayer>
            <SubHeading>Ahoy!</SubHeading>
            <MyParagraph>
                The page you were looking for appears to be lost.
                <br /> Find your way <Link to="/">home</Link>
            </MyParagraph>
        </PageSection>
    </>
);

export default PageNotFound;
